import { ReactNode } from 'react';
import { useParams } from 'react-router-dom';

import ProjectTempori from './ProjectTempori';
import ProjectShadowing from './ProjectShadowing';
import ProjectVianic from './ProjectVianic';
import Nav from '../components/nav/Nav';

const PROJECTS = new Map();

PROJECTS.set('tempori', <ProjectTempori />);
PROJECTS.set('shadowing', <ProjectShadowing />);
PROJECTS.set('vianic', <ProjectVianic />);

function ProjectNotFound() {
    return <h2> 404: Project not found! :( </h2>
}

function Project() {
    const { projectName } = useParams();
    const selectedProject: ReactNode = PROJECTS.get(projectName?.toLowerCase()) || <ProjectNotFound />;
    return <><Nav />{selectedProject}</>;
}

export default Project;
