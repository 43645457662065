import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
} from '@mui/material';
import { iSkill, skillMapData } from '../../data/skills/skills';
import { useState } from 'react';

interface SkillDialogProps {
  dialogSkill: iSkill | undefined;
  setDialogSkill: (skill: iSkill | undefined) => void;
}
function SkillDialog({ dialogSkill, setDialogSkill }: SkillDialogProps) {
  const handleCloseDialog = () => {
    setDialogSkill(undefined);
  };

  return (
    <>
      <Dialog fullWidth={true} open={!!dialogSkill} onClose={handleCloseDialog}>
        {dialogSkill && (
          <>
            <DialogTitle className="dialog-skill dialog-info">
              <Stack
                direction="row"
                justifyContent="start"
                alignItems="center"
                spacing={2}
              >
                <svg viewBox={`0 0 140 140`} width="2.2em" height="2.2em">
                  <circle
                    className="shape"
                    cx={70}
                    cy={70}
                    r={70}
                    fill="#0d1e30"
                  />
                  {dialogSkill.type === 'skill' ||
                  dialogSkill.slug === 'start' ? (
                    <g transform='translate(35 35) scale(0.5)'>{dialogSkill.logo}</g>
                  ) : (
                    <text x="70" y="90" className="title" fill="#C9D5E4" textAnchor='middle'>
                      {dialogSkill.logo}
                    </text>
                  )}
                </svg>
                <h2 className="title">{dialogSkill.title}</h2>
              </Stack>
              {dialogSkill.level && dialogSkill.levelDescription && (
                <h3 className="subtitle level-subtitle">
                  <strong>Level {dialogSkill.level}:</strong>
                  <span>{dialogSkill.levelDescription}</span>
                </h3>
              )}
            </DialogTitle>
            <DialogContent>
              <DialogContentText>{dialogSkill.description}</DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button color="primary" onClick={handleCloseDialog}>
                Ok
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    </>
  );
}

export default SkillDialog;
