import 'photoswipe/dist/photoswipe.css'
import './Project.scss';

import { useParams } from 'react-router-dom';
import { Stack } from '@mui/system';
import { Box, Chip, Avatar, ImageList, ImageListItem, ImageListItemBar } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Gallery, Item } from 'react-photoswipe-gallery';
import { BubblyLink } from "react-bubbly-transitions";

import banner from '../assets/img/projects/shadowing_Banner.jpg';
import img0App from '../assets/img/projects/shadowing_0_app.jpg';
import img1Pron from '../assets/img/projects/shadowing_1_word_prounciations.jpg';
import img2Speech from '../assets/img/projects/shadowing_2_speech_to_text.jpg';
import img3Def from '../assets/img/projects/shadowing_3_word_definition.jpg';
import img4Diff from '../assets/img/projects/shadowing_4_differences.jpg';
import img5Chart from '../assets/img/projects/shadowing_5_chart_pronunciation_by_word.jpg';

function ProjectShadowing() {
    const { projectName } = useParams();
    const url = "https://jelg-shadowing.netlify.app/";
    const galleryImgs = [
        { caption: "Shadowing App", src: img0App, id: "app", cols: 1, rows: 1 },
        { caption: "Show the words pronuntiation", src: img1Pron, id: "pronunciation", cols: 1, rows: 1 },
        { caption: "The Recordered text", src: img2Speech, id: "recordered", cols: 1, rows: 1 },
        { caption: "Show the definition of a word", src: img3Def, id: "definition", cols: 1, rows: 1 },
        { caption: "The count of each word", src: img5Chart, id: "chart", cols: 2, rows: 1 },
        { caption: "The difference between the preview text and the recordered text", src: img4Diff, id: "difference", cols: 3, rows: 1 }
    ];
    return (
        <section className="Project">
            <header className="ProjectHeader">
                <img className="projectBanner" src={banner} alt="Tempori Project Banner" loading="lazy" />
                <Stack direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={2}
                    sx={{ mb: 5 }}>
                    <BubblyLink
                        duration="500"
                        colorStart="#81A5CB"
                        colorEnd="#0D1E30"
                        to="/projects"
                    >
                        <FontAwesomeIcon className="fa-2xl" icon={icon({ name: 'circle-arrow-left', style: 'solid' })} />
                    </BubblyLink>
                    <Box sx={{ flex: "1 1" }}></Box>
                    <Stack className="tags"
                        direction="row"
                        justifyContent="space-evenly"
                        alignItems="center"
                        spacing={2}>
                        <Chip label="Javascript" color="white" />
                        <Chip label="React.js" color="white" />
                        <Chip label="IPA API" color="white" />
                    </Stack>
                </Stack>
                <Stack direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={2}>
                    <h2>{projectName}</h2>
                    <Box sx={{ flex: "1 1" }}></Box>
                    <Stack className="links"
                        direction="row"
                        justifyContent="space-evenly"
                        alignItems="center">

                        <a href={url} target="_blank" rel="noopener noreferrer" aria-label="Project link">
                            <Avatar sx={{ bgcolor: "#F5F5F5", color: "#0D1E30" }}>
                                <FontAwesomeIcon icon={icon({ name: 'link', style: 'solid' })} />
                            </Avatar>
                        </a>

                    </Stack>
                </Stack>
            </header>
            <main className='ProjectContent'>
                <Stack className="textContent">
                    <Box>
                        <h3>Overview</h3>
                        <p>
                            The shadowing app is an ongoing small-scale project aimed at facilitating English pronunciation practice.
                        </p>
                        <p>
                            This application is developed using <strong>React.js</strong> and  integrates the <a href="https://dictionaryapi.dev/" target="_blank" rel="noopener noreferrer">Free Dictionary API</a>, allowing users to access a comprehensive database for pronunciation guidance.
                        </p>
                        <p>
                            Its key features depends on some browser functions, specifically SpeechToText and TextToSpeech capabilities, for this reason in some devices this app could have some probles. It works in Google Chrome for desktop.
                        </p>
                    </Box>
                    <Box>
                        <Gallery id="tempory_gallery"
                        >

                            <ImageList
                                variant="quilted"
                                cols={3}
                                rowHeight={150}
                                gap={16}
                                sx={{ overflow: "hidden" }}
                            >


                                {galleryImgs.map(({ caption, src, id, cols, rows }) => <ImageListItem key={id} cols={cols || 1} rows={rows || 1}>

                                    <Item<HTMLImageElement>
                                        id={id}
                                        original={src}
                                        content={<Stack justifyContent="center" alignItems="center" className="ligthbox-img-container">
                                            <img
                                                className="ligthbox-img"
                                                src={src}
                                                alt={caption}
                                            />
                                        </Stack>}
                                    >
                                        {({ ref, open }) => (
                                            <img
                                                src={src}
                                                alt={caption}
                                                style={{ cursor: 'pointer', width: "100%", height: "100%", objectFit: "cover", backgroundColor: "#0D1E30", border: "solid #0D1E30 2px", boxSizing: "border-box" }}
                                                ref={ref}
                                                onClick={open}
                                                loading="lazy"
                                            />
                                        )}
                                    </Item>

                                    <ImageListItemBar
                                        // title={item.title}
                                        subtitle={caption}
                                    />
                                </ImageListItem>
                                )}

                            </ImageList>
                        </Gallery>
                    </Box>
                    <Box>
                        <p>
                            In the upcoming phases of this application, I am considering the implementation of the following tasks:
                        </p>
                        <ul>
                            <li>
                                Transition from the browser text-to-audio and speech-to-text functionalities using an external library. This modification aims to ensure greater stability across various devices.
                            </li>
                            <li>
                                Introduce the option for users to either select default pronunciations for each word or input their preferred pronunciation using the IPA alphabet.
                            </li>
                            <li>
                                Correct the errors in the identification of words caused by special characters and punctuation.
                            </li>
                        </ul>
                    </Box>
                </Stack>
            </main>
            <footer style={{ maxWidth: "100vw", marginTop: "2em", backgroundColor: "#0D1E30", padding: "1em" }}>

            </footer>
        </section >
    );
}

export default ProjectShadowing;
