import 'photoswipe/dist/photoswipe.css'
import './Project.scss';

import { useParams } from 'react-router-dom';
import { Stack } from '@mui/system';
import { Box, Chip, Avatar, ImageList, ImageListItem, ImageListItemBar } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Gallery, Item } from 'react-photoswipe-gallery';
import { BubblyLink } from "react-bubbly-transitions";

import banner from '../assets/img/projects/tempori_Banner.png';
import sketch0 from '../assets/img/projects/tempori_0_calendario_sketch.png';
import sketch1 from '../assets/img/projects/tempori_1_mapa_sketch.png';
import app1 from '../assets/img/projects/tempori_2_calendar_app.png';
import app2 from '../assets/img/projects/tempori_3_map_app.png';
import app3 from '../assets/img/projects/tempori_4_task_creation.png';

function ProjectTempori() {
  const { projectName } = useParams();
  const galleryImgs = [
    { caption: "Calendar Sketch", src: sketch0, id: "calendar_sketch", cols: 1, rows: 1 },
    { caption: "Calendar in app", src: app1, id: "calendar_app", cols: 1, rows: 2 },
    { caption: "Map Sketch", src: sketch1, id: "map_sketch", cols: 1, rows: 1 },
    { caption: "Map in app", src: app2, id: "map_app", cols: 1, rows: 2 },
    { caption: "Task creation Views", src: app3, id: "task_creation", cols: 1, rows: 2 }
  ];
  return (
    <section className="Project">
      <header className="ProjectHeader">
        <img className="projectBanner" src={banner} alt="Tempori Project Banner" loading="lazy" />
        <Stack direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
          sx={{ mb: 5 }}>
          <BubblyLink
            duration="500"
            colorStart="#81A5CB"
            colorEnd="#0D1E30"
            to="/projects"
          >
            <FontAwesomeIcon className="fa-2xl" icon={icon({ name: 'circle-arrow-left', style: 'solid' })} />
          </BubblyLink>
          <Box sx={{ flex: "1 1" }}></Box>
          <Stack className="tags"
            direction="row"
            justifyContent="space-evenly"
            alignItems="center"
            spacing={2}>
            <Chip label="Java" color="white" />
            <Chip label="Android" color="white" />
          </Stack>
        </Stack>
        <Stack direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}>
          <h2>{projectName}</h2>
          <Box sx={{ flex: "1 1" }}></Box>
          <Stack className="links"
            direction="row"
            justifyContent="space-evenly"
            alignItems="center">

            <a href="https://repository.icesi.edu.co/biblioteca_digital/handle/10906/81100" target="_blank" rel="noopener noreferrer" aria-label="Project link">
              <Avatar sx={{ bgcolor: "#F5F5F5", color: "#0D1E30" }}>
                <FontAwesomeIcon icon={icon({ name: 'link', style: 'solid' })} />
              </Avatar>
            </a>

          </Stack>
        </Stack>
      </header>
      <main className='ProjectContent'>
        <Stack className="textContent">
          <Box>
            <h3>Overview</h3>
            <p>
              Tempori was the project of my Interactive Media Design thesis, which I want to return to in the future to make a new version focused on task management.
            </p>
            <p>
              This project addressed the challenge of students transitioning from high school to university, highlighting their struggle with time management due to the change in their shifting class schedules and the increased complexity and quantity of pre and post class activities.
            </p>
            <p>
              Exploring solutions, the project culminated in a proposal for assistance through a gamified calendar application. This tool visualizes the student's challenges on a map, tracking progress day by day.
            </p>
            <p>
              The outcome was a mobile application for <strong>Android</strong>, implemented in <strong>Java</strong> and utilizing the <strong>2D Canvas</strong> for creating interactive views. My role in the project was primarily focused on the creation of views and components on the 2D canvas, while my colleague worked in design, illustrations, and animations.
            </p>
          </Box>
          <Box>
            <Gallery id="tempory_gallery"
            >

              <ImageList
                variant="quilted"
                cols={2}
                rowHeight={150}
                gap={16}
                sx={{ overflow: "hidden" }}
              >


                {galleryImgs.map(({ caption, src, id, cols, rows }) => <ImageListItem key={id} cols={cols || 1} rows={rows || 1}>

                  <Item<HTMLImageElement>
                    id={id}
                    original={src}
                    content={<Stack className="ligthbox-img-container" justifyContent="center" alignItems="center">
                      <img className="ligthbox-img"
                        src={src}
                        alt={caption}
                      />
                    </Stack>}
                  >
                    {({ ref, open }) => (
                      <img
                        src={src}
                        alt={caption}
                        style={{ cursor: 'pointer', width: "100%", height: "100%", objectFit: "cover", backgroundColor: "#0D1E30", border: "solid #0D1E30 2px", boxSizing: "border-box" }}
                        ref={ref}
                        onClick={open}
                        loading="lazy"
                      />
                    )}
                  </Item>

                  <ImageListItemBar
                    // title={item.title}
                    subtitle={caption}
                  />
                </ImageListItem>
                )}

              </ImageList>
            </Gallery>
          </Box>
          <Box>
            <p>
              Looking forward, I want to remake the project for an app release, oriented to time management support, integrating newfound best practices I  have learned since I finished this project, and migrate the project from Java to either Kotlin or React-Native.
            </p>
          </Box>
        </Stack>
      </main>
      <footer style={{ maxWidth: "100vw", marginTop: "2em", backgroundColor: "#0D1E30", padding: "1em" }}>

      </footer>
    </section >
  );
}

export default ProjectTempori;
