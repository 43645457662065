import './Skills.scss';

import React, { useState } from 'react';
import Nav from '../components/nav/Nav';
import SkillMap from '../components/skill_map/SkillMap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
} from '@mui/material';
import SkillMapV2 from '../components/skill_map/SkillMapV2';

function Skills() {
  const [dialogLevel, setDialogLevel] = useState(false);
  const handleOpenDialog = () => {
    setDialogLevel(true);
  };
  const handleCloseDialog = () => {
    setDialogLevel(false);
  };
  return (
    <section className="Skills">
      <Nav />
      <h2 className="section-title">Skills</h2>
      <Button
        className="skills-proficiency"
        size="small"
        fullWidth={false}
        onClick={handleOpenDialog}
        startIcon={
          <FontAwesomeIcon
            icon={icon({ name: 'circle-question', style: 'solid' })}
          />
        }
      >
        Levels of Proficiency
      </Button>
      
      <SkillMapV2 />
      <Dialog fullWidth={true} open={dialogLevel} onClose={handleCloseDialog}>
        <DialogTitle className="dialog-levels dialog-info">
          <h2 className="title">Levels of Proficiency:</h2>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <p>
              These proficiency levels are subjective metrics based on my
              comfort level with technologies, considering factors like duration
              of usage and project types.
              <span className="small">
                (Levels 0-2 are not on the chart but are included in a personal
                map for reference and tracking the technologies I'm interested
                in learning).
              </span>
            </p>
            <ol className="levels-list">
              <li>
                Technologies I plan to learn in the future, having heard about
                them but not tried yet.
              </li>
              <li>
                Technologies where I've seen tutorials and had introductory
                exposure, but only applied them in practice projects.
              </li>
              <li>
                Technologies I've used in personal projects, feeling
                comfortable, I'm still adapting to best practices.
              </li>
              <li>
                Technologies employed in extended academic and personal
                projects.
              </li>
              <li>Technologies used regularly in my daily work tasks.</li>
              <li>
                Technologies consistently used at work in projects for over a
                year.
              </li>
            </ol>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={handleCloseDialog}>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </section>
  );
}

export default Skills;
