import { createTheme } from "@mui/material";

declare module '@mui/material/styles' {
    interface Palette {
        bkg: Palette['primary'];
        white: Palette['primary'];
    }

    interface PaletteOptions {
        bkg?: PaletteOptions['primary'];
        white?: PaletteOptions['primary'];
    }
}
// Extend color prop on components
declare module '@mui/material/Chip' {
    export interface ChipPropsColorOverrides {
      bkg: true
      white: true
    }
  }

const theme = createTheme({
    palette: {
        primary: {
            main: '#81A5CB',
            light: '#a7c0db',
            dark: '#6784a2',
            contrastText: '#F5F5F5',
        },
        bkg: {
            main: '#0D1E30',
            light: '#3d4b59',
            dark: '#091522',
            contrastText: '#F5F5F5',
        },
        white: {
            main: '#F5F5F5',
            light: '#f9f9f9',
            dark: '#c4c4c4',
            contrastText: '#0D1E30',
        }
    },
    
});

export default theme;