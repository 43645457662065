// move to the store
import coverTempori from './../../assets/img/projects/tempori_cover.png';
import coverVianic from './../../assets/img/projects/vianic_cover.png';
import coverShadowing from './../../assets/img/projects/shadowing_cover.png';

export interface iProject {
    slug: string
    name: string
    cover: string
    tags: Array<string>
}

const PROJECTS = new Map<string, iProject>();

PROJECTS.set('tempori', {
    slug: 'tempori',
    name: 'Tempori',
    cover: coverTempori,
    tags: ["Java", "Android"]
});
PROJECTS.set('shadowing', {
    slug: 'shadowing',
    name: 'Shadowing',
    cover: coverShadowing,
    tags: ["Javascript", "React.js", "IPA API"]
});
PROJECTS.set('vianic', {
    slug: 'vianic',
    name: 'Vianic',
    cover: coverVianic,
    tags: ["Javascript", "Python", "React.js", "Django"]
});

export default PROJECTS;