import './About.scss';
import React from 'react';
import Nav from '../components/nav/Nav';
import { Box, Stack } from '@mui/system';

import profilePhoto from './../assets/img/profile_500.png';
import ContactInfo from '../components/contact_info/ContactInfo';

const conctacInfo = [
  { name: 'Linked-in' },
  { name: 'Github' },
  { name: 'Codepen' },
];

function About() {
  return (
    <section className="About">
      <Nav />
      <h2 className="section-title">About Me</h2>
      <Stack
        className="content"
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={2}
        sx={{ textAlign: 'center', flexWrap: 'wrap' }}
      >
        <Box className="description">
          <p>
            I'm a Colombian web developer who has studied the degrees in{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.icesi.edu.co/facultad-ingenieria-diseno-ciencias-aplicadas/en/interactive-media-design"
            >
              "Interactive Media Design"
            </a>{' '}
            and{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.icesi.edu.co/facultad-ingenieria-diseno-ciencias-aplicadas/en/systems-engineering"
            >
              "Software System Engineering"
            </a>{' '}
            at the Icesi University. Additionally, I completed a master's degree
            in{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.icesi.edu.co/facultad-ingenieria-diseno-ciencias-aplicadas/en/data-science-master-degree"
            >
              "Data Science"
            </a>{' '}
            at the same institution.
          </p>
          <p>
            My skills as a designer include <strong>Front-End </strong>
            Development and interface design. I started programming with
            <strong> Processing</strong> for Java, which familiarized me with
            creating code-based graphics using a 2D canvas.
            <strong> JavaScript</strong> is my primary language for Front-End
            development, with proficiency in <strong>Vue.JS</strong> and
            <strong> React.JS</strong> frameworks.
          </p>
          <p>
            As a software engineer,I have knowledge in entity modeling,
            <strong> SQL</strong> databases, and <strong>Back-End </strong>
            development, specializing in <strong>Laravel (PHP)</strong> and
            <strong> Django (Python)</strong> frameworks.
          </p>
          <p>
            I am familiar with data science activities, I mainly employ
            <strong> Python</strong>, utilizing <strong>Pandas</strong>,
            <strong> Sci-Kit Learn</strong>, <strong>Seaborn</strong> libraries,
            and having knowledge of the <strong>R</strong> language.
          </p>
        </Box>

        <Stack
          className="profile"
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={2}
        >
          <img
            className="profile_photo"
            src={profilePhoto}
            alt="Profile Photo"
          />
          <ContactInfo />
        </Stack>
      </Stack>
    </section>
  );
}

export default About;
