import React from 'react';
import './App.scss';
import { ThemeProvider } from '@mui/material';
import Router from './router/Router';
import theme from './Theme';

function App() {
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <Router />
      </ThemeProvider>
    </div>
  );
}

export default App;
