import './Contact.scss';
import React, { useState } from 'react';
import Nav from '../components/nav/Nav';
import ContactInfo from '../components/contact_info/ContactInfo';

import { Box, Stack } from '@mui/system';
import {
  TextField, Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Dialog
} from '@mui/material';

function Contact() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [DialogMessage, setDialogMessage] = React.useState<React.ReactNode | boolean>(false);
  const [clearForm, setClearForm] = useState(false);

  const handleCloseDialog = () => {
    setDialogMessage(false);
    if (clearForm) {
      setName("");
      setEmail("");
      setMessage("");
      setClearForm(false);
    }
  };

  const sendMessage = () => {
    const url = `https://docs.google.com/forms/d/e/1FAIpQLSfzUnM0Jd33cXlk3mgpd-CCzIT4J-L2b2xRjj6Ka-wIbtiICw/formResponse?usp=pp_url&entry.666307482=${name}&entry.768004034=${email}&entry.372842098=${message}&submit=Submit`;
    // https://docs.google.com/forms/d/e/[FORMID]/formResponse
    // Method GET
    fetch(url, {
      method: 'POST',
      mode: 'no-cors',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    })
      .then((resp) => {
        console.log(resp);
        const newMessage = <Box> <p>
          Hi <strong>{name}</strong>, </p><p>
            Your message "{message}" has been sent successfully.
          </p></Box>;
        setClearForm(true);
        setDialogMessage(newMessage);
        // alert('Tu mensaje se ha enviado');
      })
      .catch(() =>
        setDialogMessage(<p>There was an error sending your message, try sending an email to Juan.elg94@gmail.com.</p>)
      );
  };

  return (
    <section className="Contact">
      <Nav />
      <h2 className="section-title">Contact Me</h2>
      <Stack
        className="content"
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={12}
      >
        <Stack
          className="form"
          sx={{ textAlign: 'center' }}
          direction="column"
          justifyContent="stretch"
          alignItems="stretch"
          spacing={2}
          useFlexGap
        >
          <TextField
            color="primary"
            label="Name"
            variant="outlined"
            type="text"
            name="name"
            id="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <TextField
            color="primary"
            label="E-mail"
            variant="outlined"
            type="email"
            name="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            color="primary"
            label="Message"
            variant="outlined"
            multiline
            rows={3}
            name="message"
            id="message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
          <Box className="send-button-container">
            <Button disabled={!(email && message)} variant="contained" onClick={sendMessage}>
              Send Message
            </Button>
            {!(email && message) && <p>Please, first fill the E-mail and Message fields.</p>}
          </Box>
        </Stack>
        <ContactInfo />

        <Dialog
          fullWidth={true}
          open={!!DialogMessage}
          onClose={handleCloseDialog}
        >
          <DialogContent>
            <DialogContentText>
              {DialogMessage}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={handleCloseDialog}>Ok</Button>
          </DialogActions>
        </Dialog>
      </Stack>
    </section>
  );
}

export default Contact;
