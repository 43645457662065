import './ContactInfo.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';

import React from 'react';
import { Stack } from '@mui/system';
import Avatar from '@mui/material/Avatar';

const Contact = ({ name, img, url, type, content }) => {
  return (
    <a href={url} className="contact-link" target="_blank" key={name}>
      <Avatar className="contact-img" sx={{ width: 56, height: 56 }}>
        {type == 'fontawesome' ? (
          <FontAwesomeIcon className="fa-2x" icon={img} />
        ) : (
          <img className="profile_photo" src={img} alt={name} />
        )}
      </Avatar>
      <span className="contact-link-content">{content}</span>
    </a>
  );
};

const cList = [
  {
    name: 'Linked In',
    img: icon({ name: 'linkedin', style: 'brands' }),
    url: 'https://www.linkedin.com/in/jelg94/',
    type: 'fontawesome',
    content: 'jelg94',
  },
  {
    name: 'E-mail',
    img: icon({ name: 'envelope', style: 'regular' }),
    url: 'mailto:juan.elg94@gmail.com',
    type: 'fontawesome',
    content: 'juan.elg94@gmail.com',
  },
  {
    name: 'CV',
    img: icon({ name: 'file-lines', style: 'regular' }),
    url: '/CV_Web Developer_Juan Esteban.pdf',
    type: 'fontawesome',
    content: 'CV',
  },
  {
    name: 'Whatsapp',
    img: icon({ name: 'whatsapp', style: 'brands' }),
    url: 'https://wa.me/573012232705',
    type: 'fontawesome',
    content: '+57 3012232705',
  },
  {
    name: 'Location',
    img: icon({ name: 'earth-americas', style: 'solid' }),
    url: 'https://www.google.com/maps/place/Colombia/',
    type: 'fontawesome',
    content: 'Colombia',
  },
  /*{
    name: 'CodePen',
    img: icon({ name: 'codepen', style: 'brands' }),
    url: 'https://codepen.io/juanestebanlopez',
    type: 'fontawesome',
    content: 'juanestebanlopez',
  },*/
];

function ContactInfo({ sx = {}, contactList = [] }) {
  return (
    <Stack
      className="contact-info"
      direction="column"
      justifyContent="center"
      alignItems="center"
      spacing={2}
      sx={{ flexWrap: 'wrap', ...sx }}
    >
      {cList.map((c) => Contact(c))}
    </Stack>
  );
}
export default ContactInfo;
