import React, { useEffect } from 'react';
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';
import { BubblyContainer } from 'react-bubbly-transitions';
import ReactGA from 'react-ga4';

import Home from '../views/Home';
import About from '../views/About';
import Skills from '../views/Skills';
import Projects from '../views/Projects';
import Project from '../views/Project';
import Contact from '../views/Contact';

import Nav from '../components/nav/Nav';

ReactGA.initialize('G-TSWR91KR5M');

function handleClick() {
  // Track a custom event
  ReactGA.event({
    category: 'your category',
    action: 'your action',
    label: 'your label', // optional
    value: 99, // optional, must be a number
    nonInteraction: true, // optional, true/false
    transport: 'xhr', // optional, beacon/xhr/image
  });
  // Continue with your button's click handler
}

const RoutesList = () => {
  const location = useLocation();
  useEffect(() => {
    // ReactGA.pageview(location.pathname + location.search);
    ReactGA.send({
      hitType: 'pageview',
      page: location.pathname + location.search,
      title: location.pathname,
    });

    console.log(ReactGA, location);
  }, [location]);
  return (
    <Routes>
      <Route index path="/home" element={<Home />} />
      <Route path="/about" element={<About />} />
      <Route path="/skills" element={<Skills />} />
      <Route path="/projects" element={<Projects />} />
      <Route path="/contact" element={<Contact />} />

      <Route path="/project/:projectName" element={<Project />} />

      <Route index path="/" element={<Home />} />
      <Route path="*" element={<h1> 404</h1>} />
    </Routes>
  );
};
export default function Router() {
  return (
    <BrowserRouter>
      <BubblyContainer />
      <RoutesList />
    </BrowserRouter>
  );
}
