import 'photoswipe/dist/photoswipe.css'
import './Project.scss';

import { useParams } from 'react-router-dom';
import { Stack } from '@mui/system';
import { Box, Chip, Avatar, ImageList, ImageListItem, ImageListItemBar } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Gallery, Item } from 'react-photoswipe-gallery';
import { BubblyLink } from "react-bubbly-transitions";

import img0list from '../assets/img/projects/vianic_0_course_list.png';
import img1overview from '../assets/img/projects/vianic_1_course_overview.png';
import img2attendance from '../assets/img/projects/vianic_2_attendance_course.png';
import img3overviewDetails from '../assets/img/projects/vianic_3_overview_details.png';
import img4report from '../assets/img/projects/vianic_4_attendance_report.png';

const banner = undefined; // import banner from '../assets/img/projects/shadowing_Banner.jpg';

function ProjectVianic() {
    const { projectName } = useParams();
    const url = "";
    const galleryImgs = [
        { caption: "Course List", src: img0list, id: "app", cols: 1, rows: 1 },
        { caption: "Overview of courses in a list", src: img1overview, id: "pronunciation", cols: 1, rows: 1 },
        { caption: "Attendance of a course", src: img2attendance, id: "recordered", cols: 1, rows: 1 },
        { caption: "Overview of a single course", src: img3overviewDetails, id: "definition", cols: 1, rows: 2 },
        { caption: "Attendance report", src: img4report, id: "chart", cols: 1, rows: 1 },

    ];
    return (
        <section className="Project">
            <header className="ProjectHeader">
                {banner && <img className="projectBanner" src={banner} alt="Tempori Project Banner" loading="lazy" />}
                <Stack direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={2}
                    sx={{ mb: 5 }}>
                    <BubblyLink
                        duration="500"
                        colorStart="#81A5CB"
                        colorEnd="#0D1E30"
                        to="/projects"
                    >
                        <FontAwesomeIcon className="fa-2xl" icon={icon({ name: 'circle-arrow-left', style: 'solid' })} />
                    </BubblyLink>
                    <Box sx={{ flex: "1 1" }}></Box>
                    <Stack className="tags"
                        direction="row"
                        justifyContent="space-evenly"
                        alignItems="center"
                        spacing={2}>
                            
                        <Chip label="Javascript" color="white" />
                        <Chip label="Python" color="white" />
                        <Chip label="React.js" color="white" />
                        <Chip label="Django" color="white" />
                    </Stack>
                </Stack>
                <Stack direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={2}>
                    <h2>{projectName}</h2>
                    <Box sx={{ flex: "1 1" }}></Box>
                    <Stack className="links"
                        direction="row"
                        justifyContent="space-evenly"
                        alignItems="center">

                        {url && <a href={url} target="_blank" rel="noopener noreferrer" aria-label="Project link">
                            <Avatar sx={{ bgcolor: "#F5F5F5", color: "#0D1E30" }}>
                                <FontAwesomeIcon icon={icon({ name: 'link', style: 'solid' })} />
                            </Avatar>
                        </a>}

                    </Stack>
                </Stack>
            </header>
            <main className='ProjectContent'>
                <Stack className="textContent">
                    <Box>
                        <h3>Overview</h3>
                        <p>
                            Vianic was the project of my Software Systems Engineering thesis, addressing the challenge faced by the student support office within a university's remote programs. The goal was tracking the course progress to ensure success in the students learning experience.
                        </p>
                        <p>
                            In order for the members of the student success team to be able to carry out the appropriate actions for each student, they required information that, although it could be obtained from the LMS (Learning Management System), this process involved a labor-intensive weekly.
                        </p>
                        <p>
                            Recognizing the scalability challenges with the increasing virtual program offerings, the primary concern was the time investment required for getting answers to key questions, such as: When did the student last access the course or the LMS? Are there pending assignments? What are the deadlines for pending assignments? Are there overdue assignments? Has the teacher provided timely feedback on assignments? Does the teacher respond promptly to student messages?
                        </p>
                    </Box>
                    <Box>
                        <Gallery id="tempory_gallery"
                        >

                            <ImageList
                                variant="quilted"
                                cols={2}
                                rowHeight={150}
                                gap={16}
                                sx={{ overflow: "hidden" }}
                            >


                                {galleryImgs.map(({ caption, src, id, cols, rows }) => <ImageListItem key={id} cols={cols || 1} rows={rows || 1}>

                                    <Item<HTMLImageElement>
                                        id={id}
                                        original={src}
                                        content={<Stack className="ligthbox-img-container" justifyContent="center" alignItems="center">
                                            <img className="ligthbox-img"
                                                src={src}
                                                alt={caption}
                                            />
                                        </Stack>}
                                    >
                                        {({ ref, open }) => (
                                            <img
                                                src={src}
                                                alt={caption}
                                                style={{ cursor: 'pointer', width: "100%", height: "100%", objectFit: "cover", backgroundColor: "#0D1E30", border: "solid #0D1E30 2px", boxSizing: "border-box" }}
                                                ref={ref}
                                                onClick={open}
                                                loading="lazy"
                                            />
                                        )}
                                    </Item>

                                    <ImageListItemBar
                                        // title={item.title}
                                        subtitle={caption}
                                    />
                                </ImageListItem>
                                )}

                            </ImageList>
                        </Gallery>
                    </Box>
                    <Box>
                        <p>
                            The project outcome was a web application, developed in <strong>Django (Python)</strong>, with a <strong>React.js (Javascript)</strong> front-end. This application connected to the <strong>REST API</strong> and <strong>GraphQL API</strong> provided by the LMS Canvas to obtain the pertient data. In my role within the project, I performed both front-end and back-end tasks, which I shared with my colleague, who also worked at the university managing the LMS.
                        </p>
                    </Box>
                </Stack>
            </main>
            <footer style={{ maxWidth: "100vw", marginTop: "2em", backgroundColor: "#0D1E30", padding: "1em" }}>

            </footer>
        </section >
    );
}

export default ProjectVianic;
