import React from "react";
import "./Nav.scss";

import { BubblyLink } from "react-bubbly-transitions";
import { useTranslation, Trans } from "react-i18next";

const lngs = {
  en: { nativeName: "English" },
  es: { nativeName: "Español" },
};

function Nav() {
  const { t, i18n } = useTranslation();

  return (
    <nav className="animate-in Nav" style={{ animationDelay: "200ms" }}>
      <BubblyLink
        duration="500"
        colorStart="#81A5CB"
        colorEnd="#0D1E30"
        to="/home"
      >
        JELG
      </BubblyLink>
      <div className="space"></div>
      <BubblyLink
        duration="500"
        colorStart="#81A5CB"
        colorEnd="#0D1E30"
        to="/about"
      >
        About
      </BubblyLink>
      <BubblyLink
        duration="500"
        colorStart="#81A5CB"
        colorEnd="#0D1E30"
        to="/skills"
      >
        Skills
      </BubblyLink>
      <BubblyLink
        duration="500"
        colorStart="#81A5CB"
        colorEnd="#0D1E30"
        to="/projects"
      >
        Projects
      </BubblyLink>
      <BubblyLink
        duration="500"
        colorStart="#81A5CB"
        colorEnd="#0D1E30"
        to="/contact"
      >
        Contact
      </BubblyLink>
      <select className="select-lang"
        onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
          i18n.changeLanguage(e.target.value)
        }
        defaultValue={i18n.resolvedLanguage}
      >
        {Object.entries(lngs).map(([k, l]) => (
          <option
            key={k}
            value={k}
            disabled={i18n.resolvedLanguage === k}
          >
            {l.nativeName}
          </option>
        ))}
      </select>
    </nav>
  );
}

export default Nav;
