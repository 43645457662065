import './SkillMap.scss';

import * as d3 from 'd3';

import { skillMapData, skillConnections } from '../../data/skills/skills';
import { useEffect, useMemo, useRef, useState } from 'react';
import SkillDialog from './SkillDialog';

const DEG_WH = 128; // Ancho del rombo interior del item tipo Degree
// const DEG_MID_WH = 91; //  Fórmula: sqrt(WH**2*2) / 2    // Mitad del ancho del cuadrado exterior del item tipo Degree
const SKILL_R = 74; // Rádio de la circunferencia del item tipo Skill

const r = SKILL_R;

function degNode(node) {
  const WH = DEG_WH;
  // const midWH = DEG_MID_WH;

  node
    .append('rect')
    .attr('class', 'shape-hover')
    .attr('x', -78)
    .attr('y', -78)
    .attr('rx', 30)
    .attr('ry', 30)
    .attr('width', WH + 28)
    .attr('height', WH + 28)
    .attr('transform', 'rotate(45, 0, 0)')
    .attr('stroke-width', 4)
    .attr('stroke', '#C9D5E4')
    .attr('fill', 'transparent');

  node
    .append('rect')
    .attr('class', 'shape')
    .attr('x', -64)
    .attr('y', -64)
    .attr('rx', 23)
    .attr('ry', 23)
    .attr('width', WH)
    .attr('height', WH)
    .attr('transform', 'rotate(45, 0, 0)')
    .attr('stroke-width', 4)
    .attr('stroke', '#C9D5E4')
    .attr('fill', '#0d1e30');

  node
    .append('text')
    .attr('class', 'subtitle')
    .attr('y', 31)
    .attr('fill', '#C9D5E4')
    .attr('text-anchor', 'middle')
    .text((d) => d.subtitle || d.title);

  // Has Icon
  node
    .filter((d) => d.logoType === 'icon')
    .append('foreignObject')
    .attr('class', 'logo')
    .attr('viewBox', '0 0 100 100')
    .attr('transform', 'translate(-30, -55)')
    .attr('width', 60)
    .attr('height', 60)
    .html((d) => d.logostr);

  // No Icon
  node
    .filter((d) => d.logoType === 'text')
    .append('text')
    .attr('class', 'title')
    .attr('y', 9)
    .attr('fill', '#C9D5E4')
    .attr('text-anchor', 'middle')
    .text((d) => d.logo);
}
function skillNode(node) {
  node
    .append('circle')
    .attr('class', 'shape-hover')
    .attr('stroke-width', 4)
    .attr('stroke', '#fff')
    .attr('fill', 'transparent')
    .attr('r', r + 14);

  node
    .append('circle')
    .attr('class', 'shape')
    .attr('stroke-width', 4)
    .attr('stroke', '#fff')
    .attr('cx', 0)
    .attr('cy', 0)
    .attr('r', r)
    .attr('fill', '#0D1E30');

  node
    .filter((d) => d.logoType === 'icon')
    .append('foreignObject')
    .attr('class', 'logo')
    .attr('viewBox', '0 0 100 100')
    .attr('transform', 'translate(-40, -45)')
    .attr('width', 80)
    .attr('height', 80)
    .html((d) => d.logostr);

  node
    .append('circle')
    .attr('class', 'level-shape')
    .attr('r', 22)
    .attr('cy', r * 0.9)
    .attr('fill', 'white');

  node
    .append('text')
    .attr('y', r)
    .attr('fill', '#131320')
    .attr('text-anchor', 'middle')
    .attr('class', 'level-text')
    .text((d) => d.level);

  node
    .append('text')
    .attr('class', 'title')
    .attr('y', r * 1.7)
    .attr('fill', '#C9D5E4')
    .attr('text-anchor', 'middle')
    .text((d) => d.title);
}

function mapNode(parent, data) {
  const node = parent
    .append('g') // "use")
    // .attr("href", "#react")
    .selectAll('svg')
    .data(data)
    .join('svg')
    .attr('width', '150')
    .attr('height', '200')
    .attr('viewBox', '-90 -90 180 230')
    .attr('key', (d) => d.id)
    .attr('id', (d) => d.id)
    .attr('class', (d) => d.type);

  node
    .append('rect')
    .attr('x', -90)
    .attr('y', -90)
    .attr('width', 180)
    .attr('height', 230)
    //.attr('stroke', '#fff')
    .attr('fill', 'transparent');

  skillNode(node.filter((d) => d.type === 'skill'));
  degNode(node.filter((d) => d.type === 'deg'));

  return node;
}

function SkillMapV2() {
  const [dialogSkill, setDialogSkill] = useState(undefined);

  const handleOpenDialog = (skill) => {
    if (!skill) return;
    // console.log(skill);
    const newSkill = typeof skill === 'string' ? skillMapData[skill] : skill;
    setDialogSkill(newSkill);
  };

  const svgRef = useRef(null);

  const BASEW = 2000;
  const BASEH = 1300;
  const [imageDimension, setImageDimension] = useState({
    w: BASEW,
    h: BASEH,
  });

  const handleResize = () => {
    if (svgRef.current) {
      console.log(svgRef.current);
      const w = svgRef.current.offsetWidth; //width.baseVal.value;
      const h = svgRef.current.offsetHeight; //height.baseVal.value;
      if (w !== imageDimension.w || h !== imageDimension.h)
        setImageDimension({ w, h });
    }
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const xyAdjustment = useMemo(() => {
    const margin = 0;
    const baseRatio = BASEW / BASEH;
    const realRatio = imageDimension.w / imageDimension.h;

    let adjustment = {
      ratio: { x: 1, y: 1 },
      margin: { x: 0, y: 0 },
    };

    if (baseRatio < realRatio + margin) {
      // Excede en ancho
      const newW = BASEH * realRatio;
      adjustment.ratio.x = newW / BASEW;
      adjustment.margin.x = (newW - BASEW) / 2;
    } else if (baseRatio + margin > realRatio) {
      // Excede en largo
      const newH = BASEW / realRatio;
      adjustment.ratio.y = newH / BASEH;
      adjustment.margin.y = (newH - BASEH) / 2;
    }
    return adjustment;
  }, [imageDimension]);

  const adjust = (x, y) => ({
    x: x * xyAdjustment.ratio.x - xyAdjustment.margin.x,
    y: y * xyAdjustment.ratio.y - xyAdjustment.margin.y,
  });

  useEffect(() => {
    const width = 2000;
    const height = 1300;

    const links = Object.entries(skillConnections).flatMap(
      ([source, targets]) =>
        targets.map((target) => ({
          source,
          target,
          value: 30,
          id: source + '_' + target,
        }))
    );
    const nodes = Object.values(skillMapData).map((d) => ({
      ...d,
      id: d.slug,
      ...adjust(d.x, d.y),
    }));

    console.log({ nodes, links });

    const simulation = d3
      .forceSimulation(nodes)
      .force(
        'link',
        d3
          .forceLink(links)
          .id((d) => d.id)
          .distance(0)
          .strength(0.05)
      )
      .force('charge', d3.forceManyBody().strength(-800))
      .force('center', d3.forceCenter(width / 2, height / 2))
      .force('x', d3.forceX().strength(0.02))
      .force('y', d3.forceY().strength(0.02))
      .force('collide', d3.forceCollide().radius(150));

    // Create the SVG container.
    const svg = d3
      .select(svgRef.current)
      .append('svg')
      .attr('viewBox', [0, 0, width, height])
      .attr('style', 'width: 100%; height: 100%;');

    const container = svg
      .append('rect')
      .attr('x', 0)
      .attr('y', 0)
      .attr('width', width)
      .attr('height', height)
      // .attr('stroke', '#fff')
      .attr('fill', 'transparent');

    const root = svg.append('g');

    // Add a line for each link, and a circle for each node.
    const link = root
      .append('g')

      .attr('stroke', '#999')
      .attr('stroke-opacity', 0.6)
      .selectAll('line')
      .data(links)
      .join('line')
      .attr('stroke-width', (d) => 3) //  Math.sqrt(d.value)
      .attr('key', (d) => d.id);
    // .distance(50);

    const node = mapNode(root, nodes);

    // Node Events
    node
      .on('click', function (d) {
        handleOpenDialog(this.id);
      })
      .on('mouseover', function (d) {
        this.classList.add('hover');
        // console.log('mouseover', this);
      })
      .on('mouseout', function (d) {
        this.classList.remove('hover');
        // console.log('mouseout', this);
      });

    // Reheat the simulation when drag starts, and fix the subject position.
    const dragstarted = (event) => {
      if (!event.active) simulation.alphaTarget(0.3).restart();
      event.subject.fx = event.subject.x;
      event.subject.fy = event.subject.y;
    };

    // Update the subject (dragged node) position during drag.
    const dragged = (event) => {
      event.subject.fx = event.x;
      event.subject.fy = event.y;
    };

    // // Restore the target alpha so the simulation cools after dragging ends.
    // Unfix the subject position now that it’s no longer being dragged.
    const dragended = (event) => {
      if (!event.active) simulation.alphaTarget(0);
      event.subject.fx = null;
      event.subject.fy = null;
    };

    // Add a drag behavior.
    node.call(
      d3
        .drag()
        .on('start', dragstarted)
        .on('drag', dragged)
        .on('end', dragended)
    );

    const zoom = d3
      .zoom()
      .scaleExtent([0.4, 2])
      .on('zoom', function (event) {
        if (!event.active) simulation.alphaTarget(0.3).restart();
        root.attr('transform', event.transform);
        // console.log('event: ', event);
      });

    container.call(zoom).call(zoom.transform, d3.zoomIdentity);

    // Set the position attributes of links and nodes each time the simulation ticks.
    simulation.on('tick', () => {
      link
        .attr('x1', (d) => d.source.x)
        .attr('y1', (d) => d.source.y)
        .attr('x2', (d) => d.target.x)
        .attr('y2', (d) => d.target.y);

      node.attr('x', (d) => d.x - 75).attr('y', (d) => d.y - 75);
    });

    return () => {
      d3.select(svgRef.current).selectAll('*').remove();
    };
  }, []);
  return (
    <>
      <div
        className="ref-Container"
        ref={svgRef}
        height="100%"
        width="100%"
      ></div>
      <SkillDialog dialogSkill={dialogSkill} setDialogSkill={setDialogSkill} />
    </>
  );
}

export default SkillMapV2;
