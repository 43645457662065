import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

const initialState = {
  data: {},
};

export const dataAction = createAsyncThunk(
  'data/dataaction',
  async (_, thunkAPI) => {
    // https://redux-toolkit.js.org/introduction/getting-started
    // dispatch del loader al iniciar a acargar
    const newData = (await fetch('localhost:80/api-url')) || {
      data: 'Error, no había nada',
      error: 1,
    };

    thunkAPI.dispatch(setData(newData));

    // dispatch del loader al terminar a acargar
  }
);

export const dataSlice = createSlice({
  name: 'data',
  initialState,
  reducers: {
    setData: (state, action) => {
      state.data = action.payload;
    },
  },
});

export const { setData } = dataSlice.actions;
export default dataSlice.reducer;
