import './Home.scss'
import React, { useState } from 'react';
import Nav from '../components/nav/Nav';

import { useTranslation, Trans } from 'react-i18next';
import { Box, Stack } from '@mui/system';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';

interface Program {
  slug: string
  name: string
  description: string
  url: string
  dateRange: string
  institution: string
}

const programs = new Map<string, Program>();

programs.set("MCD", {
  slug: "MCD",
  name: "Master’s in Data Science",
  description: "As a Data Scientist I have knowledge of the activities carried out in data science, where I mainly use Python with the Pandas, Sci-Kit Learn and Seaborn libraries and I also have knowledge of the R language.",
  url: "https://www.icesi.edu.co/facultad-ingenieria-diseno-ciencias-aplicadas/en/data-science-master-degree",
  dateRange: "2022 - 2023",
  institution: "Icesi University"
});

programs.set("SIS", {
  slug: "SIS",
  name: "Bachelor's of Software Systems Engineering",
  description: "As a software engineer I am familiar with entity modeling and SQL databases, Java for desktop and android applications, and Back-End development. The main frameworks I use for Back-End are Laravel (PHP) and Django (Python).",
  url: "https://www.icesi.edu.co/facultad-ingenieria-diseno-ciencias-aplicadas/en/systems-engineering",
  dateRange: "2014 - 2021",
  institution: "Icesi University"
});

programs.set("DMI", {
  slug: "DMI",
  name: "Bachelor's of Interactive Media Design",
  description: "As a designer I have knowledge in Front-End Development and interface design, the language I use most for Front-End development is Javascript (with HTML+CSS), and I have mainly used the Vue.JS and React.JS frameworks.",
  url: "https://www.icesi.edu.co/facultad-ingenieria-diseno-ciencias-aplicadas/en/interactive-media-design",
  dateRange: "2011 - 2017",
  institution: "Icesi University"
});

function Home() {
  const { t } = useTranslation();
  const [dialogProgram, setDialogProgram] = React.useState<Program | undefined>(undefined);

  const handleOpenDialog = (program: string) => {
    console.log(program);
    const newProgram = programs.get(program);
    setDialogProgram(newProgram);
  };
  const handleCloseDialog = () => {
    setDialogProgram(undefined);
  };

  return (
    <section className="Home">
      <Nav />
      <div className="space"></div>
      <Stack className="content"
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={2}
        sx={{ textAlign: 'center', flexWrap: 'wrap' }}
      >
        <Box className="name" sx={{ flex: '1 1 60%' }}>
          <h1 className='title'>{t('home_name')}</h1>
          <h3 className='subtitle'>({t('home_role')})</h3>
        </Box>
        <Box className="degree" sx={{ flex: '1 1 40%' }} onClick={() => handleOpenDialog("DMI")}>
          <h2 className='title'>{t('home_program_1')}</h2>
          <h3 className='subtitle'>({t('home_skill_1')})</h3>
        </Box>
        <Box className="degree" sx={{ flex: '1 1 40%' }} onClick={() => handleOpenDialog("SIS")}>
          <h2 className='title'>{t('home_program_2')}</h2>
          <h3 className='subtitle'>({t('home_skill_2')})</h3>
        </Box>
        <Box className="degree" sx={{ flex: '1 1 40%' }} onClick={() => handleOpenDialog("MCD")}>
          <h2 className='title'>{t('home_program_3')}</h2>
          <h3 className='subtitle'>({t('home_skill_3')})</h3>
        </Box>
      </Stack>
      <div className="space"></div>

      <Dialog
        fullWidth={true}
        open={!!dialogProgram}
        onClose={handleCloseDialog}
      >{dialogProgram && <>
        <DialogTitle className="dialog-program dialog-info">
          <Stack direction="row" justifyContent="space-between" alignItems="baseline" spacing={2}>
            <h2 className="title">{dialogProgram.name}</h2>
            <a href={dialogProgram.url} target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={icon({ name: 'link', style: 'solid' })} />
            </a>
          </Stack>
          <h3 className="subtitle">{dialogProgram.institution} ({dialogProgram.dateRange})</h3>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {dialogProgram.description}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={handleCloseDialog}>Ok</Button>
        </DialogActions>
      </>}
      </Dialog>

    </section>
  );
}

export default Home;
