import './Projects.scss';

import React from 'react';
import Nav from '../components/nav/Nav';

import PROJECTS from './../data/projects/en';
import { Box, Chip, Paper, Stack } from '@mui/material';
import { BubblyLink } from 'react-bubbly-transitions';
const projectList = Array.from(PROJECTS.values());

function Projects() {
  return (
    <section className="Projects">
      <Nav />
      <h2 className='section-title'>Projects</h2>
      <div className="content ProjectList">
        {projectList.map(project =>

          <BubblyLink
            duration="500"
            colorStart="#81A5CB"
            colorEnd="#0D1E30"
            to={"/project/" + project.slug}
            key={project.slug}
          >
            <Paper className='ProjectItem' sx={{ ':hover': { boxShadow: 5, } }}>
              <img className="ProjectCover" src={project.cover} alt={"Project " + project.name} />
              <Stack className="ProjectContent"
              direction="column"
              justifyContent="space-between">
                <Stack className="tags"
                  direction="row"
                  justifyContent="space-evenly"
                  alignItems="center"
                  spacing={2}>
                  <div className='space' />
                  {project.tags.map(t => <Chip label={t} color="white" />)}
                </Stack>
                <div className='space' />
                <strong className="title">{project.name}</strong>
              </Stack>
            </Paper>
          </BubblyLink>

        )}
      </div>
    </section>
  );
}

export default Projects;
